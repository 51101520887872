import * as React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import Footer from "../components/Footer"
import { GatsbyImage } from 'gatsby-plugin-image';
import * as style from '../style/_post.module.scss';
import Seo from "../components/Seo"
import OgpImage from "../assets/ogpimage.jpg"

const PostPage = ({ data }) => {
  const post = data.markdownRemark;
  // const posts = data.allMarkdownRemark.nodes;
  const slug = data.markdownRemark.fields?.slug;
  const category = data.markdownRemark.frontmatter.category;
  const title = data.markdownRemark.frontmatter.title;
  const thumbnail_image =
    post.frontmatter.thumbnail.childImageSharp.gatsbyImageData;
  return (
    <div>
      <Header></Header>
      <div className={style.postWrap}>
        <GatsbyImage image={thumbnail_image} alt={title} />
        <h1>{post.frontmatter.title}</h1>
        <div className={style.categoryWrap}>
          {data.markdownRemark.frontmatter.category}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
      </div>
      <Footer></Footer>
    </div>
  );
};
export default PostPage;
export const Head = ({ data }) => (
  <Seo title={data.markdownRemark.frontmatter.title}
  ogpImgPath={data.markdownRemark.frontmatter.thumbnail.childImageSharp.gatsbyImageData.images.fallback.src}/>
 )

export const query = graphql`
  query ($slug: String) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        category
        date
        thumbnail {
          childImageSharp {
            gatsbyImageData(
              width: 1600
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;
